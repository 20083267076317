<template>
    <div v-if="total > 0 && timeframesLength > 1" class="pa-4">
        <v-card-title class="align-start justify-start pa-0 pb-2 text-caption">
            <span>{{ $t('cart.total') }}</span>
            <v-spacer />
            <span>{{ total | num(currency) }}</span>
        </v-card-title>
        <v-card-title class="align-start justify-start pa-0 pb-2 text-caption">
            <span>{{ $t('cart.totalTimeframes') }}</span>
            <v-spacer />
            <span>{{ timeframesLength }}</span>
        </v-card-title>
        <v-divider />
        <v-card-title class="align-start justify-start pa-0 pt-2 text-h5">
            <span>{{ $t('cart.totalProducts') }}</span>
            <v-spacer />
            <span>{{ total * timeframesLength | num(currency) }}</span>
        </v-card-title>
        <div class="text-caption">{{ priceType === 'taxincl' ? $t('productlist.taxincluded') : $t('productlist.taxexcluded') }}</div>
    </div>
    <div v-else-if="total > 0" class="pa-4">
        <v-card-title class="align-start justify-start pa-0 text-h5">
            <span>{{ $t('cart.totalProducts') }}</span>
            <v-spacer />
            <span>{{ total | num(currency) }}</span>
        </v-card-title>
        <div class="text-caption">{{ priceType === 'taxincl' ? $t('productlist.taxincluded') : $t('productlist.taxexcluded') }}</div>
    </div>
</template>

<script>
export default {
    name: 'acs-cart-total',
    computed: {
        products() {
            return this.$store.getters['cart/products']
        },
        total() {
            return this.products.reduce((sum, cartproduct) => {
                sum = sum + (cartproduct.quantity * cartproduct.price)
                return sum
            }, 0)
        },
        currency() {
            return this.$store.getters['sp/current'].currency_users
        },
        priceType() {
            return this.$store.getters['sp/current'].price_type
        },

        timeframesLength() {
            return this.$store.getters['cart/cart']?.timeframes?.length || 0
        }
    }
}
</script>
