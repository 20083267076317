<template>
    <div
        class="d-flex justify-space-between"
        :class="{ 'success lighten-5': audio && !disabled }">
        <div class="pr-4 text-body-1">
            <span class="font-weight-bold">{{ $t('cartdone.alert') }}</span>
            <br/>
            <div v-marked="audio ? $t('cartdone.alertHelp') : $t('cartdone.alertOff')"></div>
        </div>
        <v-btn
            fab
            x-large
            elevation="1"
            class="acs-standard-highlight"
            :color="audio ? 'success' : undefined"
            :disabled="loading || disabled"
            :loading="loading"
            @click="setSoundAlert">
            <v-icon x-large>
                {{ audio ? '$vuetify.icons.ecomSoundOn' : '$vuetify.icons.ecomSoundOff' }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
import NotificationPermission from '@/mixins/NotificationPermission'

export default {
    name: 'acs-cart-status-alert',

    mixins: [NotificationPermission],

    props: {
        orderId: { type: String },
        disabled: { type: Boolean }
    },

    data: () => ({
        loading: false
    }),

    computed: {
        audio() {
            return this.$store.getters['audio/audio']
        }
    },

    created() {
        this.$store.dispatch('audio/load')
    },

    methods: {
        setSoundAlert() {
            const value = !this.audio
            this.$store.commit('dbg/log', { message: `commit audio to ${value}` })
            this.$store.commit('audio/audio', { value: value })
            this.$store.commit('audio/alert', { forcePlay: true })

            if (value) {
                this.loading = true
                return this.notificationAskPermission({ order_id: this.orderId })
                    .catch(err => this.$err(err))
                    .finally(() => (this.loading = false))
            } else {
                this.loading = true
                return this.notificationUnsubscribe({ order_id: this.orderId })
                    .catch(err => this.$err(err))
                    .finally(() => (this.loading = false))
            }
        },

        alert() {
            this.$store.commit('audio/alert', { loop: this.$config.orders.readySoundLoop })
        }
    }
}
</script>

<style lang="sass">
.acs-cart-alert--container
    width: 100%

</style>
