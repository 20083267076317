<template>
    <v-form ref="form" v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
        <v-card :loading="loading" flat class="acs-product--form">
            <slot name="title" :loading="loading"></slot>
            <v-card-text class="pa-0">
                <acs-product-translate
                    class="px-4"
                    :loading="loading"
                    v-model="translate"
                    @change="load(true)" />

                <template v-if="product">
                    <div class="acs-product--form-scroll" v-touch="{ right: () => navigateTo('prev'), left: () => navigateTo('next') }">
                        <acs-product-image
                            :value="product.links_image"
                            :title="product.name" />

                        <acs-product-title :title="$options.filters.quickTranslate(product, 'name', translate)" />

                        <acs-product-alert-top />

                        <acs-product-description-price
                            :show-price="!!product.price"
                            :description="$options.filters.quickTranslate(product, 'description', translate)"
                            :price="product.price" />

                        <v-divider class="mx-4" />

                        <template v-if="!switchProduct">
                            <acs-product-option-group
                                v-for="group in product.options"
                                :key="group.id"
                                :group="group"
                                :translate="translate"
                                v-model="options[group.id]"
                                @input="onOptionChange(group)" />
                        </template>

                        <v-divider class="mx-4" />

                        <acs-product-information
                            :packaging="$options.filters.quickTranslate(product, 'packaging', translate)"
                            :origin="$options.filters.quickTranslate(product, 'origin', translate)"
                            :age-min="product.age_min"
                            :timeslots="product.timeslots"
                            :services="product.services" />

                        <acs-product-tags
                            v-if="product.tags.length"
                            :tags="$options.filters.quickTranslate(product, 'tags', translate)" />

                        <template v-if="product.services.length">
                            <acs-product-services
                                :slug="slug"
                                :translate="translate"
                                :service-ids="product.services" />

                            <acs-product-stock
                                :slug="slug"
                                :product-id="productId"
                                :product-unavailable="product.unavailable"
                                :quantity="quantity"
                                class="pt-0" />

                            <acs-service-timeframe-product-stock
                                :slug="slug"
                                :product-id="productId"
                                :product-unavailable="product.unavailable"
                                :quantity="quantity"
                                class="pt-0" />
                        </template>

                        <acs-product-qr
                            btn-class="mt-2"
                            :slug="slug"
                            :product="product.id"
                            :name="$options.filters.quickTranslate(product, 'name', translate)" />

                        <template v-if="product.services.length">
                            <v-divider class="mx-4" />
                            <acs-product-quantity-selector
                                v-model="quantity"
                                :actual-quantity="cartproductQuantity"
                                :min="product.cart_min"
                                :max="product.cart_max" />
                        </template>
                    </div>
                    <v-alert :value="maxReached" type="warning">
                        <router-link :to="{ name: getContextRouteName('cart') }" class="white--text">
                            {{ $t('cart.errors.productmax') }}
                        </router-link>
                    </v-alert>
                </template>
            </v-card-text>

            <v-card-actions v-if="product && product.services.length">
                <v-btn
                    rounded
                    block
                    color="primary"
                    type="submit"
                    :loading="loading"
                    :disabled="!valid">
                    {{ $t('product.addToCart', { count: total, amount: totalFormatted }) }}
                </v-btn>
            </v-card-actions>

            <acs-cart-service-popup
                v-if="product"
                v-model="showServicePopup"
                :slug="slug"
                :translate="translate"
                :service-ids="productServices"
                @selected="onServiceSelected" />
        </v-card>
    </v-form>
</template>

<script>
import AcsProductTitle from '@/components/product/AcsProductTitle'
import AcsProductImage from '@/components/product/AcsProductImage'
import AcsProductServices from '@/components/product/AcsProductServices'
import AcsProductTags from '@/components/product/AcsProductTags'
import AcsProductInformation from '@/components/product/AcsProductInformation'
import AcsProductDescriptionPrice from '@/components/product/AcsProductDescriptionPrice'
import AcsProductOptionGroup from '@/components/product/AcsProductOptionGroup'
import AcsProductQuantitySelector from '@/components/product/AcsProductQuantitySelector'
import AcsProductQr from '@/components/product/AcsProductQr'
import AcsProductStock from '@/components/product/AcsProductStock'
import AcsProductAlertTop from '@/components/product/AcsProductAlertTop'
import AcsProductTranslate from '@/components/product-list/AcsProductTranslate'
import AcsCartServicePopup from '@/components/cart/AcsCartServicePopup'
import AcsServiceTimeframeProductStock from '@/components/service-timeframe/AcsServiceTimeframeProductStock'

import RouteMixin from '@/mixins/RouteMixin'

import lodash from 'lodash'

export default {
    name: 'acs-product-form',
    components: {
        AcsProductTitle,
        AcsProductImage,
        AcsProductServices,
        AcsProductTags,
        AcsProductInformation,
        AcsProductDescriptionPrice,
        AcsProductOptionGroup,
        AcsProductQuantitySelector,
        AcsProductQr,
        AcsProductStock,
        AcsProductAlertTop,
        AcsProductTranslate,
        AcsCartServicePopup,
        AcsServiceTimeframeProductStock
    },
    props: {
        slug: { type: String },
        aslot: { type: String },
        productId: { type: String },
        hideCartIcon: { type: Boolean }
    },
    mixins: [RouteMixin],
    data: () => ({
        // problème avec des IDs d'options identiques entre différents products. A été corrigé dans
        // la partie client (#ACC-608) mais il y a toujours des articles dupliqués avec les mêmes
        // IDs d'options dans la base pour le moment
        switchProduct: false,

        valid: false,
        product: null,
        translate: false,
        loading: false,
        showServicePopup: false,
        quantity: 1,
        optionsPrices: {},
        options: {}
    }),
    computed: {
        currency() {
            return this.$store.getters['sp/current'].currency_users
        },
        total() {
            const optionsTotal = Object.values(this.optionsPrices).reduce((sum, total) => sum + total, 0)
            return (this.product.price + optionsTotal) * this.quantity
        },
        totalFormatted() {
            return this.$options.filters.num(this.total, this.currency)
        },
        filters() {
            return this.$store.getters['prod/filters'](this.slug)
        },
        serviceId() {
            return this.$store.getters['cart/cart']?.service_id
        },
        selectedTimeframes() {
            return this.$store.getters['cart/cart']?.timeframes || []
        },
        productServices() {
            // #ACC-772: si slot => on ne montre que les services-à-table. Si non, que les !service-à-table
            // uniquement dans la popup de sélection de service, au 1er ajout dans le panier
            const inSlot = this.$store.getters['sp/slot']?.id
            return (this.product.services || []).filter(serviceId => {
                const s = this.$store.getters['ser/byId'](serviceId)
                if (!s) {
                    return
                }
                return inSlot ? s.type === 'slot' : s.type !== 'slot'
            })
        },
        cartproductQuantity() {
            const cp = this.$store.getters['cart/products'].find(cp => cp.product.id === this.productId)
            return cp?.quantity || 0
        },
        maxReached() {
            return this.product?.cart_max && this.cartproductQuantity >= this.product.cart_max
        }
    },
    watch: {
        productId() {
            this.switchProduct = true
            this.load()
        },
        selectedTimeframes() {
            if (!this.product) {
                return
            }
            this.loading = true
            this.$store.commit('prod/resetAlertMessages')
            return this.checkTimeslot()
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    },
    methods: {
        load(translate) {
            if (this.loading || !this.productId) {
                return
            }
            this.loading = true

            if (!translate) {
                this.quantity = 1
                this.optionsPrices = {}
                this.options = {}
            }

            this.$store.commit('prod/resetAlertMessages')

            return this.$store
                .dispatch('prod/getProduct', {
                    slug: this.slug,
                    product_id: this.productId,
                    translate: this.translate
                })
                .then(product => {
                    this.product = product
                    if (!translate) {
                        this.quantity = product.cart_min
                        this.product.options.forEach(o => {
                            this.$set(this.options, o.id, [])
                            this.$set(this.optionsPrices, o.id, 0)
                        })
                    }
                    this.$emit('loaded', this.product)

                    return Promise.all([
                        this.checkAvailability(),
                        this.checkTimeslot()
                    ])
                })
                .catch(err => this.$err(err))
                .finally(() => {
                    this.loading = false
                    this.switchProduct = false
                })
        },

        checkAvailability() {
            if (this.product.unavailable) {
                this.$store.commit('prod/addAlertMessage', { message: this.$i18n.t('product.unavailable') })
            }
        },

        async checkTimeslot() {
            if (!this.product.include_salepoint_schedule && !this.product.timeslots.length) {
                // il n'y a pas d'horaire article ni de volonté d'utiliser ceux
                // de l'établissement, on skip
                return
            }
            const open = await this.$store.dispatch('prod/open', {
                slug: this.slug,
                product_id: this.productId,
                include_salepoint_schedules: this.product.include_salepoint_schedule
            })
            if (!open.open) {
                // pour les traductions en fonction des données vides
                this.product.timeslots.forEach(timeslot => {
                    timeslot.count = 0
                    if (!timeslot.start) {
                        timeslot.count = 1
                    }
                    if (!timeslot.end) {
                        timeslot.count = 2
                    }
                })

                const timeslots = this.product.timeslots.map(t => this.$i18n.t('product.timeslotalertpart', t))
                const tkey = this.product.timeslots.length ? 'product.timeslotalert' : 'product.timeslotalertsalepoint'

                this.$store.commit('prod/addAlertMessage', { message: this.$i18n.t(tkey, { timeslots }) })
            }
        },

        submit() {
            this.loading = true
            return Promise.resolve()
                .then(async() => {
                    if (this.$store.getters['cart/products'].length || this.serviceId) {
                        return true
                    }

                    // on s'assure que tout le formulaire est rempli correctement
                    this.$refs.form.validate()
                    await this.$nextTick()
                    if (!this.valid) {
                        return
                    }

                    const manageServiceTimeframesPopup = serviceId => {
                        if (this.$store.getters['ser/services'].find(s => s.id === serviceId).timeframe) {
                            this.$store.commit('ser/timeframesPopup', {
                                show: true,
                                onSelectedFn: () => this.addProduct()
                            })
                            return false
                        }
                        return true
                    }

                    // Ajout du 1er produit, on va d'abord récupérer le service pertinent
                    if (this.productServices.length === 1) {
                        // on a un seul service disponible, on filtre dessus
                        await Promise.all([
                            this.$store.dispatch('cart/update', {
                                createIfNotExists: true,
                                slug: this.slug,
                                service_id: this.productServices[0]
                            }),
                            this.$store.dispatch('prod/mergeFilters', {
                                slug: this.slug,
                                service: this.productServices[0]
                            })
                        ])
                        return manageServiceTimeframesPopup(this.productServices[0])
                    }
                    if (this.filters.service) {
                        // un filtre existe sur les services, on set le
                        // service sélectionné dans le panier
                        await this.$store.dispatch('cart/update', {
                            slug: this.slug,
                            service_id: this.filters.service
                        })
                        return manageServiceTimeframesPopup(this.filters.service)
                    }

                    // on montre la popup de sélection de services disponibles
                    this.showServicePopup = true
                    return false
                })
                .then(addProduct => {
                    if (!addProduct) {
                        return
                    }
                    return this.addProduct()
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        addProduct() {
            return this.$store
                .dispatch('cart/addProduct', {
                    slug: this.slug,
                    product_id: this.productId,
                    quantity: this.quantity,
                    options: lodash.flatten(Object.values(this.options))
                })
                .then(res => {
                    const back = this.$route.query.back
                    this.$success(res, {
                        message: 'cart.productAdded',
                        icon: !back && !this.hideCartIcon ? '$vuetify.icons.ecomCartGo' : undefined,
                        timeout: !back ? 2500 : undefined,
                        to: !back ? { name: this.getContextRouteName('cart') } : undefined
                    })
                    this.$emit('product:add', res)
                    if (back) {
                        return this.$router.push({ path: back })
                    }
                })
        },

        onServiceSelected(serviceId) {
            if (!serviceId) {
                return
            }
            if (this.$store.getters['ser/services'].find(s => s.id === serviceId).timeframe) {
                this.$store.commit('ser/timeframesPopup', {
                    show: true,
                    onSelectedFn: () => this.addProduct()
                })
                return
            }

            this.loading = true
            return this.$store.dispatch('cart/update', { createIfNotExists: true, slug: this.slug, service_id: serviceId })
                .then(() => this.addProduct())
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        navigateTo(direction) {
            if (this.showServicePopup || this.loading || this.popup) {
                return
            }
            this.$store.commit('prod/navigateTo', { direction })
        },

        onOptionChange(group) {
            const options = group.items.reduce((acc, item) => {
                acc[item.id] = item
                return acc
            }, {})

            this.optionsPrices[group.id] = this.options[group.id].reduce((sum, { id, value }) => {
                if (!options[id]) {
                    return sum
                }
                let price = options[id].price
                if (options[id].type === 'list' && options[id].list?.length) {
                    const listitem = options[id].list.find(l => l.value === value)
                    price = listitem?.price
                }
                return sum + (price || 0)
            }, 0)
        }
    }
}
</script>

<style lang="sass">
.acs-product--form

    .acs-product--form-scroll-tmp
        height: calc(100vh - 140px)
        overflow-y: scroll

</style>
