<template>
    <v-textarea
        v-model="message"
        :label="$t('cart.message')"
        :counter="max"
        :rules="[$length(max)]"
        outlined />
</template>

<script>
export default {
    name: 'acs-cart-message',
    props: {
        value: { type: String }
    },
    data: () => ({
        max: 200
    }),
    computed: {
        message: {
            get() {
                return this.value || ''
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
