<template>
    <v-alert
        :value="show"
        color="orange lighten-2">
        <template v-if="minError">
            <v-row>
                <v-col>{{ $t('cartpay.minAmount') }}</v-col>
                <v-col class="shrink">{{ min | num(currency) }}</v-col>
            </v-row>

            <strong>{{ $t('cartpay.minAmountHelp', { amount: $options.filters.num(missing, currency) }) }}</strong>
        </template>
        <template v-else>
            <v-row>
                <v-col>{{ $t('cartpay.maxAmount') }}</v-col>
                <v-col class="shrink">{{ max | num(currency) }}</v-col>
            </v-row>
        </template>

        <v-input
            type="hidden"
            :rules="[$required]"
            :value="!show"
            single-line
            hide-details />
    </v-alert>
</template>

<script>
export default {
    name: 'acs-cart-amount',
    props: {
        min: { type: [String, Number] },
        max: { type: [String, Number] },
        total: { type: [String, Number] }
    },
    computed: {
        show() {
            return this.minError || this.maxError
        },

        minError() {
            return this.min > 0 && Math.round(this.missing * 100) / 100 > 0
        },

        maxError() {
            return this.max && this.total > this.max
        },

        missing() {
            return this.min - this.total
        },

        currency() {
            return this.$store.getters['sp/current'].currency_users
        }
    }
}
</script>
