<template>
    <v-card-title class="text-h5 pb-0">{{ title }}</v-card-title>
</template>

<script>
export default {
    name: 'acs-product-title',
    props: {
        title: { type: String }
    }
}
</script>
