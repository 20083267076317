<template>
    <div>
        <h4>{{ $t('cart.service') }}</h4>
        <v-select
            v-if="services.length"
            v-model="service"
            :items="services"
            :loading="loading"
            item-text="name"
            item-value="id"
            :rules="[$required]"
            outlined>
            <template #item="{ item }">
                {{ item | quickTranslate('name', translate) }}
            </template>
            <template #selection="{ item }">
                {{ item | quickTranslate('name', translate) }}
            </template>
        </v-select>

        <v-alert type="error" v-else>
            {{ $t('cart.noservice') }}
            <v-input
                type="hidden"
                :rules="[$required]"
                hide-details
                single-line
                :value="null" />
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'acs-cart-service-selector',
    props: {
        slug: { type: String },
        value: { type: String },
        translate: { type: Boolean }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        services() {
            return this.$store.getters['ser/orderableServices']
        },
        service: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        translate(v) {
            v && this.load(true)
        }
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('ser/services', { slug: this.slug, translate: this.translate })
                .then(() => {
                    if (!this.services.find(s => s.id === this.service)) {
                        // le service du panier n'est plus disponible dans la liste
                        this.service = this.services.length ? this.services[0].id : null
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
