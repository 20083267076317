<template>
    <acs-base-container large size="large">
        <acs-product-form
            :slug="slug"
            :aslot="aslot"
            :product-id="product"
            @loaded="loaded" />

    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsProductForm from '@/components/product/AcsProductForm'

export default {
    name: 'product-form',
    components: { AcsBaseContainer, AcsProductForm },
    props: {
        product: { type: String, required: true },
        category: { type: String, required: true },
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    mounted() {
        // this.$store.commit('cls/add', { type: 'html', cls: 'acs-scrollable' })
        this.load()
    },
    beforeDestroy() {
        // this.$store.commit('cls/remove', { type: 'html', cls: 'acs-scrollable' })
    },
    methods: {
        load() {
            return Promise.all([
                this.$store.dispatch('sp/current', { slug: this.slug }),
                this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot }),
                this.$store.dispatch('prod/categories', { slug: this.slug })
            ])
        },

        loaded(product) {
            this.$store.commit('top/title', { title: this.$options.filters.quickTranslate(product, 'name', true) })
        }
    }
}
</script>
