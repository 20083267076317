<template>
    <div class="acs-cart-age--container flex-row align-center mt-4">
        <span class="v-input"
             :class="{ 'error--text': error }">
             {{ $t('cartpay.age', { age: maxAge }) }}
        </span>
        <v-switch
            color="primary"
            class="mt-0"
            v-model="accepted"
            hide-details
            :rules="rules"
            @update:error="v => error = v" />
    </div>
</template>

<script>
export default {
    name: 'acs-cart-age',
    props: {
        value: { type: Boolean }
    },
    data: () => ({
        error: false
    }),
    computed: {
        accepted: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        maxAge() {
            const products = this.$store.getters['cart/products']
            return products.reduce((max, cartproduct) => {
                return max >= cartproduct.product.age_min || 0 ? max : cartproduct.product.age_min
            }, 0)
        },

        rules() {
            const rules = []
            if (this.maxAge > 0) {
                rules.push(this.$required)
            }
            return rules
        }
    },
    watch: {
        maxAge(v) {
            this.$emit('update:max-age', v)
        }
    },
    mounted() {
        // on emit le maxage et ses éventuels updates car c'est le
        // composant parent qui devra (ou non) cacher ce champ si
        // aucun âge n'est spécifié
        this.$emit('update:max-age', this.maxAge)
    }
}
</script>

<style lang="sass">
.acs-cart-age--container
    display: flex
</style>
