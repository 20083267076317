<template>
    <div>
        <h4>{{ $t('cart.orderMode') }}</h4>
        <v-radio-group v-model="mode" row mandatory class="mt-0">
            <v-radio v-for="m in modes"
                :key="m.id"
                :label="$t(`shared-front:order.modes.${m.id}`)"
                :value="m.id" />
        </v-radio-group>
    </div>
</template>

<script>
export default {
    name: 'acs-cart-order-mode-selector',
    props: {
        value: { type: String },
        slug: { type: String },
        serviceId: { type: [String, Number] }
    },
    data: () => ({
        loading: false,
        modes: []
    }),
    computed: {
        mode: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        serviceId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('ser/orderModes', { slug: this.slug, serviceId: this.serviceId })
                .then(modes => {
                    this.modes = modes
                    if (this.modes.length === 1) {
                        this.mode = this.modes[0].id
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
