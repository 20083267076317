<template>
    <v-list>
        <div class="d-flex px-4 text-subtitle-2">
            {{ group | quickTranslate('name', translate) }}
            <template v-if="!group.advanced">
                <span v-if="group.max" class="pl-1">({{ selected.length }}/{{ group.max }})</span>
                <span v-else class="pl-1">({{ selected.length }})</span>
            </template>
            <v-spacer />
            <span v-if="group.max || group.min">({{ optionsText }})</span>
        </div>

        <v-radio-group v-if="!group.advanced" v-model="choice" class="mt-0" hide-details>
            <v-list-item-group
                multiple
                v-model="selected"
                :max="group.max || undefined"
                :mandatory="isSoloChoice && !!selected.length"
                color="primary">
                <v-list-item
                    v-for="(item, i) in group.items"
                    :key="item.id"
                    @click="select(item, i)"
                    :disabled="!isSoloChoice && !!group.max && selected.length === group.max && selected.indexOf(i) === -1">
                    <template #default="{ active }">
                        <v-list-item-action>
                            <v-radio v-if="isSoloChoice" :value="i" />
                            <v-checkbox v-else :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item | quickTranslate('name', translate) }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action-text
                            v-if="showPrices"
                            class="option-price"
                            v-html="$options.filters.sign(item.price, currency, { small: true, full: true })">
                        </v-list-item-action-text>
                    </template>
                </v-list-item>
            </v-list-item-group>
            <v-input
                class="px-4"
                type="hidden"
                :rules="rules"
                :name="`options_${group.id}`"
                v-model="selected.length" />
        </v-radio-group>

        <template v-else>
            <acs-product-option-group-advanced
                v-for="item in group.items"
                v-model="optionValue[item.id]"
                :values="values"
                :key="item.id"
                :group="group"
                :item="item"
                :translate="translate"
            />
        </template>
    </v-list>
</template>

<script>
import AcsProductOptionGroupAdvanced from '@/components/product/AcsProductOptionGroupAdvanced'

import lodash from 'lodash'

/**
 * Gère la sélection des options. On fait la distinction entre
 * - 1 et 1 seul choix, obligatoire : min = max = 1
 * - tous les autres cas de figure possible
 *
 * Le 1er cas implique des radio buttons, les autres des checkboxes.
 * Comme pour les radios, il faut un radiogroup, on place l'élément
 * autour du list-item. Ça ne perturbe pas les checkboxes, et ça
 * permet de faire fonctionner l'autre cas.
 */
export default {
    name: 'acs-product-option-group',
    components: { AcsProductOptionGroupAdvanced },
    props: {
        group: { type: Object },
        value: { type: Array },
        values: { type: Array },
        translate: { type: Boolean }
    },
    data: () => ({
        choice: null,
        optionValue: {}
    }),
    computed: {
        selected: {
            get() {
                // map id on index
                return (this.value || []).map(v => this.group.items.findIndex(i => i.id === v.id))
            },
            set(v) {
                // map index on id
                this.$emit('input', lodash.compact(v.map(index => {
                    if (this.group.items[index]) {
                        return { id: this.group.items[index].id }
                    }
                    return null
                })))
            }
        },

        showPrices() {
            for (let i = 0; i < this.group.items.length; i += 1) {
                if (this.group.items[i].price !== 0) {
                    return true
                }
            }
            return false
        },

        rules() {
            const rules = [this.$ranged(this.group.min, this.group.max)]
            if (this.group.min) {
                rules.push(this.$required)
            }
            return rules
        },

        optionsText() {
            if (!this.group.min && !this.group.max) {
                return ''
            }
            if (this.group.min === this.group.max) {
                return this.$i18n.t('productoption.choose', { count: this.group.min })
            }
            if (!this.group.min) {
                return this.$i18n.t('productoption.chooseMax', { count: this.group.max })
            }
            if (!this.group.max) {
                return this.$i18n.t('productoption.chooseMin', { count: this.group.min })
            }
            return this.$i18n.t('productoption.chooseRange', { min: this.group.min, max: this.group.max })
        },

        isSoloChoice() {
            return this.group.min === 1 && this.group.max === 1
        },

        currency() {
            return this.$store.getters['sp/current'].currency_users
        }
    },
    watch: {
        optionValue: {
            deep: true,
            handler: lodash.debounce(function(v) {
                this.$emit('input', Object.entries(v).map(([optionId, value]) => {
                    return { id: optionId, value }
                }))
            }, 50)
        }
    },
    async mounted() {
        // on doit attendre un tout petit peu, car au moment du mount, les
        // max=min=1 + defaultValue ne se set pas comme il faut. Le list-item
        // ne s'active pas, alors que le radio button oui
        await new Promise(resolve => window.setTimeout(resolve, 300))

        this.group.items.forEach((item, i) => {
            if (item.is_default) {
                if (this.isSoloChoice) {
                    this.choice = i
                    this.selected = [i]
                } else {
                    this.selected.push(i)
                }
            }
        })
        // manip qui permet de forcer le trigger du watch:selected, le push() ne l'active pas.
        // on ne le fait pas pour les radios, à cause de la sélection qui bug
        if (!this.isSoloChoice) {
            this.selected = this.selected.slice()
        }
    },
    methods: {
        select(item, index) {
            if (this.isSoloChoice) {
                this.choice = index
                this.selected = [index]
            }
        }
    }
}
</script>

<style lang="sass">
.option-price
    font-size: 1rem

</style>
