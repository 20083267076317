<template>
    <v-list three-line>
        <acs-product-translate
            :loading="loading"
            v-model="translate"
            @change="load(false)" />

        <acs-product-filter
            ref="filters"
            :disabled="loading"
            :translate="translate"
            :slug="slug"
            @change="load(true)" />

        <acs-product-category-image
            v-if="categoryNode && categoryNode.links_image"
            :value="categoryNode.links_image"
            :title="$options.filters.quickTranslate(categoryNode, 'name', translate)"
            cmp-class="mb-2" />

        <acs-base-loader v-if="loading" :permanent="true" />

        <template v-if="!loading && productsByCategory.length">
            <template v-for="c in productsByCategory">
                <v-subheader
                    v-if="c.category.id !== category"
                    :key="`h-${c.category.id}`"
                    class="grey lighten-3">
                    <div class="acs-product-list-category-breadcrumb">
                        <span v-for="b in c.breadcrumb" :key="`b-${b.id}`">
                            {{ $options.filters.quickTranslate(b, 'name', translate) }}
                        </span>
                    </div>
                </v-subheader>

                <template v-for="product in c.products">
                    <acs-product-list-item
                        :key="product.id"
                        :slug="slug"
                        :product="product"
                        :translate="translate" />

                    <v-divider :key="`i-${product.id}`" />
                </template>
            </template>
        </template>

        <template v-else-if="!loading && filtersActive">
            <div class="text-center">
                <v-card-text>
                    <img src="/img/list-empty.svg" />
                </v-card-text>
                <v-card-text class="text-h5">{{ $t('productlist.noresult') }}</v-card-text>
                <v-card-text>{{ $t('productlist.noresultHelp') }}</v-card-text>
            </div>
        </template>

        <acs-base-empty-list v-else :value="!loading && !productsByCategory.length" :text="$t('productlist.empty')" />
    </v-list>
</template>

<script>
import AcsBaseEmptyList from '@/shared/components/base/AcsBaseEmptyList'
import AcsProductListItem from '@/components/product-list/AcsProductListItem'
import AcsProductTranslate from '@/components/product-list/AcsProductTranslate'
import AcsProductFilter from '@/components/product-list/AcsProductFilter'
import AcsProductCategoryImage from '@/components/product-category/AcsProductCategoryImage'

export default {
    name: 'acs-product-list',
    components: {
        AcsProductListItem,
        AcsBaseEmptyList,
        AcsProductTranslate,
        AcsProductFilter,
        AcsProductCategoryImage
    },
    props: {
        slug: { type: String },
        category: { type: String }
    },
    data: () => ({
        loading: false,
        translate: false,
        products: [],
        filtersActive: false
    }),
    computed: {
        filters() {
            return this.$store.getters['prod/filters'](this.slug)
        },

        categoryNode() {
            return this.$store.getters['prod/findNode'](this.category)
        },

        categoriesFlat() {
            return this.$store.getters['prod/categoriesFlat']
        },

        productsByCategory() {
            return Object.values(this.products.reduce((acc, p) => {
                if (!acc[p.category_id]) {
                    acc[p.category_id] = {
                        category: this.categoriesFlat.find(c => c.id === p.category_id),
                        breadcrumb: this.$store.getters['prod/breadcrumb'](p.category_id, { from_node_id: this.category }),
                        products: []
                    }
                }
                acc[p.category_id].products.push(p)
                return acc
            }, {}))
        }
    },
    async mounted() {
        !this.translate && await this.load()
    },
    methods: {
        load(countProducts) {
            this.loading = true
            this.filtersActive = this.filters?.service || !!this.filters?.tags?.length
            window.setTimeout(() => this.$refs.filters?.cancel(), 100)
            return this.$store
                .dispatch('prod/categories', {
                    slug: this.slug,
                    translate: this.translate
                })
                .then(() => {
                    this.$emit('categories:loaded')
                    return this.$store.dispatch('prod/products', {
                        slug: this.slug,
                        category_id: this.$store.getters['prod/categoryIds'](this.category),
                        service: this.filters?.service,
                        tags: this.filters?.tags,
                        timeframes: this.filters?.timeframes,
                        translate: this.translate
                    })
                })
                .then(products => {
                    this.products = products
                    this.$store.commit('prod/navigation', { products: products })
                    return products
                })
                .then(products => this.$emit('loaded', products))
                .then(() => countProducts && this.$store.dispatch('prod/countProducts', {
                    slug: this.slug,
                    service: this.filters?.service,
                    tags: this.filters?.tags,
                    timeframes: this.filters?.timeframes
                }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.acs-product-list-category-breadcrumb
    text-transform: uppercase

.acs-product-list-category-breadcrumb span+span:before
    content: "-"
</style>
