<template>
    <acs-base-qrcode-btn @input="onScan">
        <template #button="{ on }">
            <v-btn
                v-on="on"
                color="primary"
                outlined
                rounded
                large
                :loading="loading">
                <v-icon class="mr-2">$vuetify.icons.baseQr</v-icon>
                {{ $t('productlist.nextscan') }}
            </v-btn>
        </template>
    </acs-base-qrcode-btn>
</template>

<script>
import AcsBaseQrcodeBtn from '@/shared/components/base/AcsBaseQrcodeBtn'

export default {
    name: 'acs-cart-scan-product-btn',
    components: { AcsBaseQrcodeBtn },

    props: {
        loading: { type: Boolean }
    },

    methods: {
        onScan(url) {
            // on a ici une URL myaccessing valide. On va maintenant récupérer
            // l'ID de l'article
            const regs = [
                /\/ecom\/cart\?scanpid=([0-9a-zA-Z-]+)/,
                // on peut encore vérifier si on a scanné un QR d'accès direct
                // à l'article. Dans ce cas, on peut également en extraire l'id
                /\/ecom\/categories\/[0-9a-zA-Z-]+\/products\/([0-9a-zA-Z-]+)/

            ]
            for (let i = 0; i < regs.length; i += 1) {
                const matches = url.match(regs[i])
                if (matches?.length && matches[1]) {
                    return this.$emit('scan', { product_id: matches[1] })
                }
            }
            this.$err(new Error(this.$i18n.t('productlist.scanerror.noproduct')))
        }
    }
}
</script>
