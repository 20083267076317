<template>
    <v-list-item>
        <v-list-item-content class="acs-product-advanced--item">
            <component
                v-model="valueLocal"
                :is="componentName"
                :item="item"
                :translate="translate"
                :attributes="attributes"
                :required="required"
                :label="$options.filters.quickTranslate(item, 'name', translate)" />
        </v-list-item-content>
        <v-list-item-action-text
            v-if="showPrices"
            class="option-price pl-3"
            v-html="$options.filters.sign(price, currency, { small: true, full: true })">
        </v-list-item-action-text>
    </v-list-item>
</template>

<script>
import AcsProductOptionTypeText from '@/components/product/type/AcsProductOptionTypeText'
import AcsProductOptionTypeTextarea from '@/components/product/type/AcsProductOptionTypeTextarea'
import AcsProductOptionTypeInteger from '@/components/product/type/AcsProductOptionTypeInteger'
import AcsProductOptionTypeFloat from '@/components/product/type/AcsProductOptionTypeFloat'
import AcsProductOptionTypePhone from '@/components/product/type/AcsProductOptionTypePhone'
import AcsProductOptionTypeEmail from '@/components/product/type/AcsProductOptionTypeEmail'
import AcsProductOptionTypeUrl from '@/components/product/type/AcsProductOptionTypeUrl'
import AcsProductOptionTypeCheckbox from '@/components/product/type/AcsProductOptionTypeCheckbox'
import AcsProductOptionTypeList from '@/components/product/type/AcsProductOptionTypeList'
import AcsProductOptionTypeRange from '@/components/product/type/AcsProductOptionTypeRange'
import AcsProductOptionTypeDate from '@/components/product/type/AcsProductOptionTypeDate'
import AcsProductOptionTypeDatetime from '@/components/product/type/AcsProductOptionTypeDatetime'
import AcsProductOptionTypeTime from '@/components/product/type/AcsProductOptionTypeTime'
import AcsProductOptionTypeWeek from '@/components/product/type/AcsProductOptionTypeWeek'
import AcsProductOptionTypeMonth from '@/components/product/type/AcsProductOptionTypeMonth'

export default {
    name: 'acs-product-option-group-advanced',
    components: {
        AcsProductOptionTypeText,
        AcsProductOptionTypeTextarea,
        AcsProductOptionTypeInteger,
        AcsProductOptionTypeFloat,
        AcsProductOptionTypePhone,
        AcsProductOptionTypeEmail,
        AcsProductOptionTypeUrl,
        AcsProductOptionTypeCheckbox,
        AcsProductOptionTypeList,
        AcsProductOptionTypeRange,
        AcsProductOptionTypeDate,
        AcsProductOptionTypeDatetime,
        AcsProductOptionTypeTime,
        AcsProductOptionTypeWeek,
        AcsProductOptionTypeMonth
    },
    props: {
        value: { type: [String, Number, Boolean] },
        values: { type: Array },
        group: { type: Object },
        item: { type: Object },
        translate: { type: Boolean }
    },

    computed: {
        required() {
            const required = (this.attributes.required || '').toLowerCase()
            return ['1', 'on', 'yes', 'true', 'required'].includes(required)
        },

        attributes() {
            return this.item.attrs.reduce((acc, attr) => {
                acc[attr.name] = attr.value
                return acc
            }, {})
        },

        currency() {
            return this.$store.getters['sp/current'].currency_users
        },

        componentName() {
            return `acs-product-option-type-${this.item.type}`
        },

        showPrices() {
            return this.item.type === 'list' && !!this.item.list?.find(l => l.price)
        },

        price() {
            if (!this.showPrices) {
                return
            }
            const listitem = this.item.list.find(l => l.value === this.valueLocal)
            return listitem?.price || 0
        },

        initialValue() {
            if (!this.values) {
                return undefined
            }
            const data = this.values.find(v => v.id === this.item.id)
            return data?.value || undefined
        },

        valueLocal: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },

    async mounted() {
        await new Promise(resolve => window.setTimeout(resolve, 1000))
        if (this.initialValue) {
            this.valueLocal = this.initialValue
        }
    }
}
</script>

<style lang="sass">
.acs-product-advanced--item
    overflow: initial
</style>
