<template>
    <v-dialog v-model="show" max-width="300" :persistent="loading">
        <v-card :loading="loading">
            <v-card-actions class="pa-5">
                <v-icon left color="green">$vuetify.icons.baseInfo</v-icon>
                <h2 class="text-subtitle-2">{{ $t('cartservicepopup.title') }}</h2>
            </v-card-actions>
            <v-divider />
            <v-card-text class="mt-3 black--text">
                <h2 class="text-subtitle-2">{{ $t('cartservicepopup.select') }}</h2>
                <v-radio-group v-model="selected">
                    <v-radio
                        v-for="service in services"
                        :key="service.id"
                        :value="service.id">
                        <template #label>
                            {{ service | quickTranslate('name', translate) }}
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-card-actions class="px-0">
                    <v-btn
                        block
                        rounded
                        large
                        :disabled="!selected"
                        :loading="loading"
                        color="primary"
                        @click="select">
                        {{ $t('Valider') }}
                    </v-btn>
                </v-card-actions>
                <p class="text-overline">{{ $t('cartservicepopup.help') }}</p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'acs-cart-service-popup',
    props: {
        slug: { type: String },
        value: { type: Boolean },
        translate: { type: Boolean },
        serviceIds: { type: Array, default: () => [] }
    },
    data: () => ({
        selected: null,
        loading: false
    }),
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        services() {
            return this.$store.getters['ser/orderableServices'].filter(s => {
                return !this.serviceIds.length || this.serviceIds.indexOf(s.id) !== -1
            })
        }
    },
    mounted() {
        this.loading = true
        return this.$store.dispatch('ser/services', { slug: this.slug })
            .then(() => {
                if (this.services.length === 1) {
                    this.selected = this.services[0].id
                }
            })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },
    methods: {
        select() {
            this.loading = true
            return this.$store
                .dispatch('cart/update', {
                    slug: this.slug,
                    service_id: this.selected
                })
                .then(() => this.$store.dispatch('prod/mergeFilters', {
                    slug: this.slug,
                    service: this.selected
                }))
                .then(() => {
                    this.$emit('selected', this.selected)
                    this.show = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
