<template>
    <v-dialog v-model="show" persistent max-width="400">
        <v-card>
            <acs-base-card-title :title="$t('cartproductscan.options.title')" :dismissible="false" />
            <v-card-text class="mt-4">
                <div v-marked="$t('cartproductscan.options.text')"></div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn outlined small rounded @click="toForm">{{ $t('cartproductscan.options.toform') }}</v-btn>
                <v-spacer />
                <v-btn outlined small rounded color="primary" @click="$emit('click:add-to-cart', { product })">
                    {{ $t('cartproductscan.options.tocart') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-cart-scan-product-option-popup',

    components: { AcsBaseCardTitle },

    props: {
        value: { type: Boolean },
        product: { type: Object }
    },

    mixins: [RouteMixin],

    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },

    methods: {
        toForm() {
            this.$router.push({
                name: this.getContextRouteName('productform'),
                params: {
                    category: this.product.category_id,
                    product: this.product.id
                }
            })
        }
    }
}
</script>
