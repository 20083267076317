<template>
    <v-card-text class="acs-product--information">
        <div class="text-subtitle-2">{{ $t('product.services') }}</div>
        <span class="text-caption">{{ $t('product.serviceshelp') }}</span>
        <ul class="text-caption">
            <li v-for="service in servicesDisplay" :key="service.id">
                {{ service | quickTranslate('name', translate) }}
            </li>
        </ul>
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-product-services',
    props: {
        slug: { type: String },
        serviceIds: { type: Array, default: () => [] },
        translate: { type: Boolean }
    },
    computed: {
        services() {
            return this.$store.getters['ser/orderableServices']
        },

        servicesDisplay() {
            return this.services.filter(s => this.serviceIds.indexOf(s.id) !== -1)
        }
    },
    watch: {
        translate(v) {
            v && this.load()
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            return this.$store.dispatch('ser/services', {
                slug: this.slug,
                translate: this.translate
            })
        }
    }
}
</script>
