<template>
    <v-card-text v-if="visible" class="acs-product--information pb-0">
        <div class="text-subtitle-2">{{ $t('product.information') }}</div>
        <div class="acs-content text-caption">
            <span v-if="packaging">{{ $t('product.packaging') }}: {{ packaging }}</span>
            <span v-if="origin">{{ $t('product.origin') }}: {{ origin }}</span>
            <span v-if="ageMin">{{ $t('product.age') }}: {{ ageMin }} ans</span>
        </div>
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-product-information',
    props: {
        packaging: { type: String },
        origin: { type: String },
        ageMin: { type: [String, Number] },
        timeslots: { type: Array },
        services: { type: Array }
    },
    computed: {
        visible() {
            return !!(this.packaging || this.origin || this.ageMin)
        }
    }
}
</script>

<style lang="sass" scoped>
.acs-product--information
    span:not(:last-child):after
        content: ', '

</style>
