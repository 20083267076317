<template>
    <v-card flat>
        <v-card-title class="text-subtitle-2 pb-0">
            <div>{{ cartproduct.product | quickTranslate('name', translate) }}</div>
            <v-spacer />
            {{ total | num(currency) }}
        </v-card-title>
        <v-card-text v-if="cartproduct.options.length" class="text-caption pb-0">
            {{ $t('cart.optionsTitle') }}
            <div v-for="(group, i) in options" :key="i">
                <strong>{{ group | quickTranslate('name', translate) }}</strong>
                <ul>
                    <li v-for="option in group.items" :key="option.id">
                        {{ option | quickTranslate('name', translate) }}
                        <span v-if="option.value !== undefined">: {{ option.value }}</span>
                        <span v-if="option.price">: <span v-html="$options.filters.sign(option.price, currency, { small: true })"></span></span>
                    </li>
                </ul>
            </div>
        </v-card-text>

        <acs-cart-product-error class="mx-3 mb-0" v-if="error" :error="error" />

        <v-card-actions class="px-3">

            <acs-cart-product-quantity
                v-model="quantity2"
                :disabled="loading"
                :min="cartproduct.product.cart_min"
                :max="cartproduct.product.cart_max"
                @input="updateQuantity" />

            <v-spacer />
            <v-btn text small @click="remove" :loading="loading">
                {{ $t('cart.productRemove') }}
                <v-icon right small>$vuetify.icons.baseDelete</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import AcsCartProductQuantity from '@/components/cart/AcsCartProductQuantity'
import AcsCartProductError from '@/components/cart/AcsCartProductError'

export default {
    name: 'acs-cart-product',
    components: { AcsCartProductQuantity, AcsCartProductError },
    props: {
        slug: { type: String },
        quantity: { type: [String, Number] },
        cartproduct: { type: Object },
        translate: { type: Boolean },
        error: { type: Object, default: () => null }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        currency() {
            return this.$store.getters['sp/current'].currency_users
        },

        options() {
            const mainOptions = this.cartproduct.product.options
                .reduce((acc, group, i) => acc.concat(group.items.map(item => ({ ...item, parent_index: i }))), [])

            const options = this.cartproduct.options
                .map(opt => {
                    const o = mainOptions.find(option => option.id === opt.id)

                    let price = 0
                    if (o.type === 'list' && o.list?.length) {
                        const listitem = o.list.find(l => l.value === opt.value)
                        price = listitem?.price || 0
                    } else {
                        price = o.price
                    }

                    return o && {
                        id: o.id,
                        name: o.name,
                        name_translated: o.name_translated,
                        value: opt.value,
                        price,
                        parent: this.cartproduct.product.options[o.parent_index].name,
                        parent_translated: this.cartproduct.product.options[o.parent_index].name_translated
                    }
                })
                .filter(opt => !!opt)
                .reduce((acc, option) => {
                    if (!acc[option.parent]) {
                        acc[option.parent] = {
                            name: option.parent,
                            name_translated: option.parent_translated,
                            items: []
                        }
                    }
                    acc[option.parent].items.push(option)
                    return acc
                }, {})

            return Object.values(options)
        },

        total() {
            return this.quantity * this.cartproduct.price
        },

        quantity2: {
            get() {
                return this.quantity
            },
            set(v) {
                this.$emit('update:quantity', v)
            }
        }
    },
    methods: {
        updateQuantity() {
            this.loading = true
            return this.$store
                .dispatch('cart/updateProductQuantity', {
                    slug: this.slug,
                    cartproduct_id: this.cartproduct.id,
                    product_id: this.cartproduct.product.id,
                    quantity: this.cartproduct.quantity
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        remove() {
            this.loading = true
            // le dispatch enlève l'élément du tableau. Le composant est retiré du parent
            // Si on met un $emit dans le .then, il ne sera jamais écouté coté parent, car
            // ce composant sera détruit avant
            this.removing = this.cartproduct.id
            return this.$store.dispatch('cart/removeProduct', { slug: this.slug, cartproduct_id: this.cartproduct.id })
                .then(res => this.$success(res, { message: 'cart.productRemoved' }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
