<template>
    <acs-base-phone-field
        v-model="phone"
        :auto-salepoint-default="true"
        :required="required"
        :rules="required ? [$required] : []"
        :label="label + (required ? ' *' : '')" />
</template>

<script>
import AcsBasePhoneField from '@/shared/components/base/AcsBasePhoneField'

export default {
    name: 'acs-product-option-type-phone',
    components: { AcsBasePhoneField },
    props: {
        value: { type: String },
        item: { type: Object },
        label: { type: String },
        required: { type: Boolean },
        attributes: { type: Object, default: () => ({}) }
    },
    computed: {
        phone: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
