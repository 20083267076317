<template>
    <v-card-text v-if="stock && stock.use_stock">
        <p class="text-caption mb-0" v-marked.inline="$t('productstock.remaining', { count: stock.remaining, max: stock.max })"></p>
        <p v-if="stock.type !== 'manual'" class="text-caption mb-0">
            {{ $t('productstock.turnover', { date: nextTurnover }) }}
        </p>
        <v-input type="hidden" hide-details :rules="[$required]" :value="orderable" />
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-product-stock',
    props: {
        slug: { type: String },
        productId: { type: String },
        productUnavailable: { type: Boolean },
        quantity: { type: [String, Number] }
    },
    data: () => ({
        loading: false,
        stock: null
    }),
    computed: {
        nextTurnover() {
            if (!this.stock?.next_turnover) {
                return
            }
            const turnover = new Date(this.stock.next_turnover)
            return turnover.toLocaleDateString(this.$store.getters['ln/currentWithRegion'], {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric'
            })
        },
        disabledUntil() {
            if (!this.stock?.disabled_until) {
                return
            }
            const turnover = new Date(this.stock.disabled_until)
            return turnover.toLocaleDateString(this.$store.getters['ln/currentWithRegion'], {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric'
            })
        },
        orderable() {
            if (!this.stock?.use_stock) {
                return
            }
            const orderable = this.stock.orderable
            const remaining = Math.round(this.stock.remaining || 0) - (this.quantity || 0)
            return orderable && remaining >= 0
        }
    },
    watch: {
        productId: 'load'
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('prod/getStock', { slug: this.slug, product_id: this.productId })
                .then(async stock => {
                    this.stock = stock

                    await this.$nextTick()

                    if (stock.type === 'manual' && this.productUnavailable) {
                        // si le produit est désactivé, on ne traite
                        // pas le stock manuel.
                        return
                    }

                    if (this.orderable === false) {
                        let msg = this.$i18n.t('productstock.nostock')
                        if (this.disabledUntil || this.nextTurnover) {
                            msg = ' ' + this.$i18n.t('productstock.turnover', { date: this.disabledUntil || this.nextTurnover })
                        }
                        this.$store.commit('prod/addAlertMessage', { type: 'stock', skip: ['unavailable'], message: msg })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
