<template>
    <v-list-item
        @click="popup ? $emit('click', product) : undefined"
        :to="!popup ? { name: getContextRouteName('productform'), params: { product: product.id } } : undefined"
        :class="{ 'v-list-item--unavailable': product.unavailable }"
        exact>
        <v-list-item-content class="acs-product-list--item-content">
            <v-list-item-subtitle
                class="acs-product-list--item-title"
                :class="{ 'grey--text lighten-1': product.unavailable }">
                {{ product | quickTranslate('name', translate) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle :class="{ 'grey--text lighten-1': product.unavailable }">{{ product | quickTranslate('description', translate) | unmarked }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action
            v-if="product.price"
            class="ml-4 acs-product-list--item-action"
            :class="{ 'grey--text lighten-1': product.unavailable }">
            {{ product.price | num(currency) }}
        </v-list-item-action>
        <v-list-item-action class="ml-0 acs-product-list--item-action">
            <v-icon :color="product.unavailable ? 'grey lighten-1' : 'primary'">$vuetify.icons.baseRight</v-icon>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-product-category-list-item',
    props: {
        slug: { type: String },
        product: { type: Object },
        translate: { type: Boolean },
        popup: { type: Boolean }
    },
    mixins: [RouteMixin],
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        currency() {
            return this.salepoint.currency_users
        }
    }
}
</script>

<style lang="sass">
.acs-product-list--item-title
    color: rgba(0, 0, 0, 0.87) !important
    font-size: 1rem

.acs-product-list--item-action, .acs-product-list--item-action
    align-self: center !important

</style>
