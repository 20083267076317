<template>
    <v-card :loading="loading" flat class="acs-product--form">
        <acs-product-translate
            class="px-4"
            :loading="loading"
            v-model="translate"
            @change="load(true)" />

        <v-form v-if="product" ref="form" v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
            <div>
                <acs-product-title :title="$options.filters.quickTranslate(product, 'name', translate)" />

                <acs-product-alert-top />

                <acs-product-description-price
                    :show-price="!!product.price"
                    :description="$options.filters.quickTranslate(product, 'description', translate)"
                    :price="product.price" />

                <v-divider class="mx-4" />

                <template v-if="!switchProduct">
                    <v-subheader v-if="product.options && product.options.length">{{ $t('cartadvanced.options') }}</v-subheader>
                    <acs-product-option-group
                        v-for="group in product.options"
                        :key="group.id"
                        :group="group"
                        :translate="translate"
                        :values="getGroupOptionsValues(group)"
                        v-model="options[group.id]"
                        @input="onOptionChange(group)" />

                    <template v-if="service && service.timeframe && service.timeframe.options && service.timeframe.options.length">
                        <v-subheader>{{ $t('cartadvanced.optionstimeframes') }}</v-subheader>
                        <acs-product-option-group
                            v-for="group in service.timeframe.options"
                            :key="group.id"
                            :group="group"
                            :translate="translate"
                            :values="getGroupOptionsValues(group)"
                            v-model="options[group.id]"
                            @input="onOptionChange(group)" />
                    </template>
                </template>

                <v-divider class="mx-4" />

                <acs-product-information
                    :age-min="product.age_min"
                    :timeslots="product.timeslots" />

                <acs-service-advanced-stock
                    v-if="serviceId"
                    :slug="slug"
                    :service-id="serviceId"
                    :quantity="quantity"
                    class="pt-0" />
            </div>

            <div v-if="hasTimeframePrice || hasAdvancedPrice">
                <v-card-title v-if="hasAdvancedPrice && hasTimeframePrice" class="align-start justify-start py-1 text-caption">
                    <span>{{ $t('cart.totalOptionsAdvanced') }}</span>
                    <v-spacer />
                    <span>{{ totalOptionsAdvanced | num(currency) }}</span>
                </v-card-title>
                <template v-if="timeframesLength > 0 && hasTimeframePrice">
                    <template v-if="timeframesLength > 1">
                        <v-card-title class="align-start justify-start py-1 text-caption">
                            <span>{{ $t('cart.optionsTimeframes') }}</span>
                            <v-spacer />
                            <span>{{ totalOptionsTimeframe | num(currency) }}</span>
                        </v-card-title>
                        <v-card-title class="align-start justify-start py-1 text-caption">
                            <span>{{ $t('cart.totalTimeframes') }}</span>
                            <v-spacer />
                            <span>{{ timeframesLength }}</span>
                        </v-card-title>
                    </template>
                    <v-card-title v-if="hasAdvancedPrice" class="align-start justify-start py-1 text-caption">
                        <span>{{ $t('cart.totalOptionsTimeframes') }}</span>
                        <v-spacer />
                        <span>{{ totalOptionsTimeframe * timeframesLength | num(currency) }}</span>
                    </v-card-title>
                </template>
                <v-divider />
                <v-card-title class="align-start justify-start py-1 text-h5">
                    <span>{{ $t('cart.totalOptions') }}</span>
                    <v-spacer />
                    <span>{{ totalOptionsAdvanced + (totalOptionsTimeframe * timeframesLength) | num(currency) }}</span>
                </v-card-title>
            </div>

            <v-card-actions>
                <v-btn
                    rounded
                    block
                    color="primary"
                    type="submit"
                    :loading="loading"
                    :disabled="!valid">
                    {{ $t('cartadvanced.addToCart') }}
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import AcsProductTitle from '@/components/product/AcsProductTitle'
import AcsProductInformation from '@/components/product/AcsProductInformation'
import AcsProductDescriptionPrice from '@/components/product/AcsProductDescriptionPrice'
import AcsProductOptionGroup from '@/components/product/AcsProductOptionGroup'
import AcsProductAlertTop from '@/components/product/AcsProductAlertTop'
import AcsProductTranslate from '@/components/product-list/AcsProductTranslate'
import AcsServiceAdvancedStock from '@/components/service/AcsServiceAdvancedStock'

import RouteMixin from '@/mixins/RouteMixin'

import lodash from 'lodash'

export default {
    name: 'acs-service-advanced-form',
    components: {
        AcsProductTitle,
        AcsProductInformation,
        AcsProductDescriptionPrice,
        AcsProductOptionGroup,
        AcsServiceAdvancedStock,
        AcsProductAlertTop,
        AcsProductTranslate
    },
    props: {
        slug: { type: String }
    },
    mixins: [RouteMixin],
    data: () => ({
        valid: false,
        switchProduct: false,
        product: null,
        translate: false,
        loading: false,
        quantity: 1,
        optionsPricesValues: [],
        optionsPrices: {},
        options: {}
    }),
    computed: {
        cart() {
            return this.$store.getters['cart/cart']
        },
        allOptions() {
            return (this.product.options || []).concat(this.service?.timeframe?.options || [])
        },
        optionsIndexed() {
            return this.allOptions.reduce((acc, group) => {
                group.items.forEach(item => {
                    acc[item.id] = group.id
                })
                return acc
            }, {})
        },
        currency() {
            return this.$store.getters['sp/current'].currency_users
        },

        optionsAdvancedGroupIds() {
            return (this.product.options || []).map(group => group.id)
        },

        optionsTimeframeGroupdIds() {
            return (this.service?.timeframe?.options || []).map(group => group.id)
        },

        hasTimeframePrice() {
            return !!(this.service?.timeframe?.options || []).find(g => g.items.find(item => {
                return item.price || (item.list || []).find(l => l.price)
            }))
        },

        hasAdvancedPrice() {
            return !!(this.product.options || []).find(g => g.items.find(item => {
                return item.price || (item.list || []).find(l => l.price)
            }))
        },

        totalOptionsAdvanced() {
            return Object.entries(this.optionsPrices)
                .filter(o => this.optionsAdvancedGroupIds.includes(o[0]))
                .reduce((acc, o) => acc + o[1], 0)
        },

        totalOptionsTimeframe() {
            return Object.entries(this.optionsPrices)
                .filter(o => this.optionsTimeframeGroupdIds.includes(o[0]))
                .reduce((acc, o) => acc + o[1], 0)
        },

        timeframesLength() {
            return this.cart?.timeframes?.length || 0
        },

        serviceId() {
            return this.cart?.service_id
        },
        service() {
            return this.$store.getters['ser/services'].find(s => s.id === this.cart.service_id)
        }
    },
    watch: {
        serviceId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load(translate) {
            if (this.loading || !this.serviceId) {
                return
            }
            this.loading = true

            if (!translate) {
                this.switchProduct = true
                this.quantity = 1
                this.optionsPricesValues = []
                this.optionsPrices = {}
                this.options = {}
            }

            this.$store.commit('prod/resetAlertMessages')

            return this.$store
                .dispatch('ser/advanced', {
                    slug: this.slug,
                    service_id: this.serviceId,
                    translate: this.translate
                })
                .then(product => {
                    this.product = product
                    if (!translate) {
                        this.allOptions.forEach(o => {
                            this.$set(this.options, o.id, [])
                            this.$set(this.optionsPrices, o.id, 0)
                        })
                    }
                    this.$emit('loaded', this.product)

                    return this.checkTimeslot()
                })
                .catch(err => this.$err(err))
                .finally(() => {
                    this.switchProduct = false
                    this.loading = false
                })
        },

        async checkTimeslot() {
            if (!this.product.include_salepoint_schedule && !this.product.timeslots.length) {
                // il n'y a pas d'horaire article ni de volonté d'utiliser ceux
                // de l'établissement, on skip
                return
            }
            const open = await this.$store.dispatch('ser/advancedOpen', {
                slug: this.slug,
                service_id: this.serviceId,
                include_salepoint_schedules: this.product.include_salepoint_schedule
            })
            if (!open.open) {
                // pour les traductions en fonction des données vides
                this.product.timeslots.forEach(timeslot => {
                    timeslot.count = 0
                    if (!timeslot.start) {
                        timeslot.count = 1
                    }
                    if (!timeslot.end) {
                        timeslot.count = 2
                    }
                })

                const timeslots = this.product.timeslots.map(t => this.$i18n.t('product.timeslotalertpart', t))
                const tkey = this.product.timeslots.length ? 'product.timeslotalert' : 'product.timeslotalertsalepoint'

                this.$store.commit('prod/addAlertMessage', { message: this.$i18n.t(tkey, { timeslots }) })
            }
        },

        submit() {
            this.loading = true
            return Promise.resolve()
                .then(async() => {
                    // on s'assure que tout le formulaire est rempli correctement
                    this.$refs.form.validate()
                    await this.$nextTick()
                    if (!this.valid) {
                        return
                    }

                    return this.$store.dispatch('ser/advancedSave', {
                        slug: this.slug,
                        service_id: this.serviceId,
                        quantity: this.quantity,
                        options: lodash.flatten(Object.values(this.options))
                    })
                })
                .then(async res => {
                    this.$success(res, { message: 'cartadvanced.saved' })
                    return this.$store.dispatch('cart/get', { slug: this.slug })
                })
                .then(() => this.$router.push({ name: this.getContextRouteName('cartpay') }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onOptionChange(group) {
            const options = group.items.reduce((acc, item) => {
                acc[item.id] = item
                return acc
            }, {})

            this.optionsPrices[group.id] = this.options[group.id].reduce((sum, { id, value }) => {
                if (!options[id]) {
                    return sum
                }
                let price = options[id].price
                if (options[id].type === 'list' && options[id].list?.length) {
                    const listitem = options[id].list.find(l => l.value === value)
                    price = listitem?.price
                }
                return sum + (price || 0)
            }, 0)
            this.optionsPricesValues = Object.values(this.optionsPrices)
        },

        getGroupOptionsValues(group) {
            const data = this.cart.advanced?.options || []
            return data.filter(opt => this.optionsIndexed[opt.id] === group.id)
        }
    }
}
</script>
