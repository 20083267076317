<template>
    <v-card-text v-if="orderable !== null">
        <p class="text-caption mb-0">
            <span
                v-for="s in stock"
                :key="s.date"
                v-marked.inline="$t('servicetimeframeproduct.stockremaining', { count: s.remaining, max: s.max, date: s.date })"></span>
        </p>
        <v-input type="hidden" hide-details :rules="[$required]" :value="orderable" />
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-service-timeframe-product-stock',
    props: {
        slug: { type: String },
        productId: { type: String },
        productUnavailable: { type: Boolean },
        quantity: { type: [String, Number] }
    },
    data: () => ({
        loading: false,
        stock: []
    }),
    computed: {
        orderable() {
            if (this.stock.filter(s => s.use_stock).length === 0) {
                return null
            }
            const unavailable = this.stock.filter(s => {
                const orderable = s.orderable
                const remaining = Math.round(s.remaining || 0) - (this.quantity || 0)
                return !orderable || remaining < 0
            })
            return unavailable.length === 0
        },
        filters() {
            return this.$store.getters['prod/filters'](this.slug)
        },
        serviceId() {
            return this.$store.getters['cart/cart']?.service_id || this.filters?.service
        },
        timeframes() {
            return this.$store.getters['cart/cart']?.timeframes || []
        }
    },
    watch: {
        productId: 'load',
        serviceId: 'load',
        timeframes: 'load'
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            if (!this.serviceId || this.loading || !this.timeframes.length) {
                return
            }
            this.loading = true
            return this.$store
                .dispatch('ser/getProductStock', {
                    slug: this.slug,
                    service_id: this.serviceId,
                    product_id: this.productId,
                    dates: this.timeframes
                })
                .then(stock => {
                    this.stock = stock

                    if (this.orderable === false) {
                        const msg = this.$i18n.t('servicetimeframeproduct.nostock', { count: stock.length })
                        this.$store.commit('prod/addAlertMessage', { type: 'stock', skip: ['unavailable'], message: msg })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
