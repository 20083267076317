<template>
    <acs-base-container large size="large">
        <acs-product-list
            :slug="slug"
            :category="category"
            @categories:loaded="categoriesLoaded" />

    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsProductList from '@/components/product-list/AcsProductList'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'product-list',
    components: { AcsBaseContainer, AcsProductList },
    props: {
        category: { type: String, required: true },
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    mixins: [RouteMixin],
    mounted() {
        this.load()
    },
    methods: {
        load() {
            return Promise.all([
                this.$store.dispatch('sp/current', { slug: this.slug }),
                this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            ])
        },

        categoriesLoaded() {
            const category = this.$store.getters['prod/findNode'](this.category)
            if (!category) {
                return
            }
            this.$store.commit('top/title', { title: this.$options.filters.quickTranslate(category, 'name', true) })
        }
    }
}
</script>
