<template>
    <acs-base-container large size="large">
        <acs-service-advanced-form
            v-if="cart"
            :slug="slug" />

    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsServiceAdvancedForm from '@/components/service/AcsServiceAdvancedForm'

export default {
    name: 'service-advanced-form',
    components: { AcsBaseContainer, AcsServiceAdvancedForm },
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    computed: {
        cart() {
            return this.$store.getters['cart/cart']
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            return Promise.all([
                this.$store.dispatch('cart/get', { slug: this.slug }),
                this.$store.dispatch('sp/current', { slug: this.slug }),
                this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            ])
        }
    }
}
</script>
