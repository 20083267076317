<template>
    <acs-base-container center>
        <v-card flat>
            <v-card-text class="acs--survey-message">
                <img src="/img/pay-cancel.svg" />
            </v-card-text>
            <v-card-title class="justify-center">
                <div class="text-h5">{{ $t('cartdone.cancel') }}</div>
            </v-card-title>
            <v-card-text>
                {{ $t('cartdone.cancelHelp') }}
            </v-card-text>
            <v-card-actions>
                <v-btn
                    rounded
                    color="primary"
                    block
                    :to="{ name: getContextRouteName('cart') }">
                    {{ $t('cartdone.retry') }}
                </v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    :to="{ name: getContextRouteName('menulist') }">
                    {{ $t('cartdone.stop') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'cart-cancel',
    components: { AcsBaseContainer },
    props: {
        slug: { type: String },
        aslot: { type: String }
    },
    mixins: [RouteMixin],
    mounted() {
        return this.$store.dispatch('sp/current', { slug: this.slug })
    }
}
</script>
