<template>
    <v-dialog v-model="show" scrollable>
        <template #activator="{ on, attrs }">
            <div>
                <v-img
                    v-if="value"
                    :src="`${value}&w=${width}&f=cover`"
                    :alt="title"
                    :title="title"
                    :max-height="height"
                    v-on="on"
                    v-bind="attrs">
                    <template #placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-3" />
                        </v-row>
                    </template>
                </v-img>
            </div>
        </template>
        <v-card>
            <acs-base-card-title @close="show = false" hide-spacer>
                <template #buttons>
                    <v-spacer />
                    <v-btn dark text icon small target="_blank" :href="value" class="mr-3">
                        <v-icon small>$vuetify.icons.baseDownload</v-icon>
                    </v-btn>
                </template>
            </acs-base-card-title>
            <v-card-text class="pa-0">
                <v-img
                    :src="value"
                    :alt="title"
                    :title="title">
                    <template #placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-3" />
                        </v-row>
                    </template>
                </v-img>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

export default {
    name: 'acs-product-image',
    components: { AcsBaseCardTitle },
    props: {
        value: { type: String },
        title: { type: String }
    },
    data: () => ({
        show: false
    }),
    computed: {
        height() {
            return this.$vuetify.breakpoint.mdAndUp ? 520 : 260
        },
        width() {
            return this.$vuetify.breakpoint.mdAndUp ? 800 : 500
        }
    }
}
</script>
