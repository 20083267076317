<template>
    <v-card-text class="d-flex">
        <div class="flex-grow-1 text-caption" v-marked="description"></div>
        <div v-if="showPrice" class="font-weight-bold pl-5">{{ price | num(currency) }}</div>
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-product-description-price',
    props: {
        description: { type: String },
        price: { type: [String, Number] },
        showPrice: { type: Boolean, default: true }
    },
    computed: {
        currency() {
            return this.$store.getters['sp/current'].currency_users
        }
    }
}
</script>
