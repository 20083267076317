<template>
    <v-dialog v-model="show" max-width="320">
        <template #activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                text
                color="primary"
                class="text-subtitle-2 acs-button-link"
                :class="btnClass">
                <v-icon left>$vuetify.base.qr</v-icon>
                {{ $t('product.qr') }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">{{ name }}</v-card-title>

            <v-card outlined class="mb-3 mx-5">
                <v-alert type="error" v-model="error">
                    {{ $t('product.qrError') }}
                </v-alert>
                <v-card-text class="px-5">
                    <v-img
                        :src="url"
                        :alt="$t('QR code')"
                        :title="$t('QR code')"
                        min-height="50"
                        @loadstart="start"
                        @load="end(false)"
                        @error="end(true)">
                        <template #placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <acs-base-loader v-model="loading" />
                            </v-row>
                        </template>
                    </v-img>
                </v-card-text>
            </v-card>

            <v-card-actions>
                <v-btn
                    block
                    rounded
                    color="primary"
                    @click="show = false">
                    {{ $t('shared-front:base.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'acs-product-qr',
    props: {
        slug: { type: String },
        product: { type: String },
        name: { type: String },
        btnClass: { type: [String, Object, Function] }
    },
    data: () => ({
        show: false,
        loading: false,
        error: false
    }),
    computed: {
        url() {
            return `${this.$api.defaults.baseURL}/salepoints/${this.slug}/ecom/products/${this.product}/qr`
        }
    },
    methods: {
        start() {
            this.error = false
            this.loading = true
        },

        end(error) {
            this.error = error
            this.loading = false
        }
    }
}
</script>
