<template>
    <v-text-field
        v-model="range"
        :label="label+ (required ? ' *' : '')"
        v-bind="attributes"
        type="range"
        dense
        outlined
        hide-details
        :rules="required ? [$required] : []"
    />
</template>

<script>
export default {
    name: 'acs-product-option-type-range',
    props: {
        value: { type: String },
        item: { type: Object },
        label: { type: String },
        required: { type: Boolean },
        attributes: { type: Object, default: () => ({}) }
    },
    computed: {
        range: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
