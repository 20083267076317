<template>
    <v-alert type="warning">
        {{ $t(`cart.errors.${error.type}`, error.details || {}) }}
    </v-alert>
</template>

<script>
export default {
    name: 'acs-cart-product-error',
    props: {
        error: { type: Object }
    }
}
</script>
