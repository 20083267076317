<template>
    <v-card-text class="d-flex align-center">
        <div class="text-subtitle-2 grow">
            {{ $t(i18nLabel, { min, max }) }}
        </div>
        <v-input type="hidden" value="" :rules="maxReached ? [$required] : []" />
        <div class="acs-product--quantity-container">
            <v-text-field
                single-line
                hide-details
                outlined
                dense
                class="mt-0 pt-1"
                type="number"
                :min="min"
                :max="maximum"
                v-model="quantity"
                :rules="[$required, $ranged(min, maximum, true)]">
                <template #prepend>
                    <v-icon color="primary" @click="add(-1)" :disabled="quantity <= min">mdi-minus-circle</v-icon>
                </template>
                <template #append-outer>
                    <v-icon color="primary" @click="add(1)" :disabled="quantity >= maximum">mdi-plus-circle</v-icon>
                </template>
            </v-text-field>
        </div>
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-product-quantity-selector',
    props: {
        value: { type: [String, Number] },
        actualQuantity: { type: [String, Number] },
        min: { type: Number },
        max: { type: Number }
    },
    computed: {
        quantity: {
            get() {
                return this.value || this.value === 0 ? Number(this.value) : null
            },
            set(v) {
                this.$emit('input', v !== null ? Number(v) : v)
            }
        },
        maximum() {
            return this.max || 999
        },
        i18nLabel() {
            if (this.min <= 1 && !this.max) {
                return 'product.quantity'
            }
            if (this.min > 1 && !this.max) {
                return 'product.quantity_one'
            }
            if (this.min <= 1 && this.max) {
                return 'product.quantity_two'
            }
            if (this.min === this.max) {
                return 'product.quantity_three'
            }
            return 'product.quantity_four'
        },
        maxReached() {
            return this.actualQuantity + this.quantity > this.maximum
        }
    },

    methods: {
        add(increment) {
            if (this.quantity + increment < (this.min || 0)) {
                this.quantity = this.min || 0
            } else if (this.quantity + increment > this.maximum) {
                this.quantity = this.maximum
            } else {
                this.quantity += increment
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.acs-product--quantity-container
    max-width: 160px

</style>
