<template>
    <v-text-field
        v-model="num"
        ref="field"
        type="number"
        v-bind="fieldProps"
        :step="allowDecimal ? '.01' : '1'"
        :rules="rules"
        @keydown="onKeyDown">
        <template #prepend>
            <slot name="prepend"></slot>
        </template>
        <template #prepend-inner>
            <slot name="prepend-inner"></slot>
        </template>
        <template #append>
            <slot name="append"></slot>
        </template>
        <template #append-outer>
            <slot name="append-outer"></slot>
        </template>
    </v-text-field>
</template>

<script>
// import lodash from 'lodash'

export default {
    name: 'acs-base-number-field',
    props: {
        value: { type: [String, Number] },
        factor: { type: Number, default: 1 },
        allowDecimal: { type: Boolean, default: true },
        convert: { type: Boolean },
        rules: { type: Array },
        fieldProps: { type: Object, default: () => ({}) }
    },
    computed: {
        num: {
            get() {
                if (!this.value && this.value !== 0) {
                    return null
                }
                const test = String(this.value)
                if (test.search(/[^0-9]/) !== -1) {
                    // we must avoid calculation if we're writing a zero after coma
                    // return this.value
                }
                // needed because of imprecision of javascript Math
                if (this.factor !== 1) {
                    const precision = this.precisionForMultiplication(this.value, this.factor)
                    const result = (this.value * this.factor).toFixed(precision)
                    return String(result)
                }
                return String(this.value)
            },
            set(v) {
                if (!v && v !== 0) {
                    return this.$emit('input', null)
                }
                if (v.search(/[^0-9]/) !== -1) {
                    // we must avoid calculation if we're writing coma or a zero
                    return this.$emit('input', v)
                }
                // needed because of imprecision of javascript Math
                let result = v
                if (this.factor !== 1) {
                    const precision = this.precisionForDivision(Number(v), this.factor)
                    result = (Number(v) / this.factor).toFixed(precision)
                }
                this.$emit('input', this.convert ? Number(result) : result)
            }
        }
    },
    methods: {
        focus() {
            this.$refs.field.focus()
        },

        onKeyDown(ev) {
            const check = ['Backspace', 'Delete', 'Escape', 'Enter', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
            if (!this.allowDecimal && check.indexOf(ev.key) === -1 && isNaN(ev.key)) {
                ev.stopPropagation()
                ev.preventDefault()
            }
        },

        precisionForDivision(v, factor) {
            let scale = 0
            while (v > 0.1) {
                scale += 1
                v /= 10
            }
            const factorLength = String(factor).length - 1
            const decimals = scale + factorLength
            return decimals > 0 ? decimals : 0
        },

        precisionForMultiplication(v, factor) {
            v = String(v)
            const coma = v.search(/[^0-9]/)
            if (coma === -1) {
                return 0
            }
            const decimals = v.length - coma - 1
            const factorLength = String(factor).length - 1
            const result = decimals - factorLength
            return result > 0 ? result : 0
        }
    }
}
</script>
