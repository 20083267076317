<template>
    <acs-base-container large size="large">
        <acs-product-category-list
            v-if="salepoint.id"
            :slug="slug"
            :category="category"
            max-width="400"
            class="acs-home--card"
            @loaded="loaded" />

    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsProductCategoryList from '@/components/product-category/AcsProductCategoryList'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'product-category-list',
    components: { AcsBaseContainer, AcsProductCategoryList },
    mixins: [RouteMixin],
    props: {
        category: { type: String, required: true },
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            return Promise.all([
                this.$store.dispatch('sp/current', { slug: this.slug }),
                this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            ])
        },

        loaded() {
            const category = this.$store.getters['prod/findNode'](this.category)
            if (!category) {
                return
            }
            if (category.flatten) {
                // si la catégorie est plate, on redirige directement sur la liste des produits
                return this.$router.replace({ name: this.getContextRouteName('productlist'), params: { category: category.id } })
            }
            this.$store.commit('top/title', { title: this.$options.filters.quickTranslate(category, 'name', true) })
        }
    }
}
</script>
