import Vue from 'vue'
import { urlBase64ToUint8Array } from '@/plugins/functions'

export default {
    computed: {
        notificationEnabled() {
            return ('PushManager' in window) && ('serviceWorker' in navigator) && this.notificationSalepointSlug
        },
        notificationSalepointSlug() {
            return this.$store.getters['sp/current']?.slug
        }
    },

    methods: {
        async notificationRegisterSubscription(filters) {
            try {
                const token = this.$store.getters['notif/token']
                const registration = await navigator.serviceWorker.getRegistration()

                const subscription = await registration.pushManager.getSubscription().then(async subscription => {
                    if (subscription) {
                        // unsubscribe puis régénère une nouvelle subscription, pour
                        // renouveler l'expirationTime
                        await this.$store.dispatch('notif/unsubscribe', { token, subscription, slug: this.notificationSalepointSlug, filters })
                        await subscription.unsubscribe()
                    }
                    // Get the server's public key
                    const vapidPublicKey = await this.$store.dispatch('notif/getConfig')

                    // subscribe the user (userVisibleOnly allows to specify that we don't plan to
                    // send notifications that don't have a visible effect for the user).
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
                        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
                    })
                })

                return this.$store.dispatch('notif/subscribe', { token, subscription, slug: this.notificationSalepointSlug, filters })
            } catch (error) {
                // eslint-disable-next-line
                console.error(error)
                return Vue.prototype.$api.post('/devlog', { type: 'notification-register', error: error.message }).catch(console.error)
            }
        },

        async notificationAskPermission(filters) {
            if (!this.notificationEnabled) {
                this.$store.commit('dbg/log', { message: `notif push: not available (PushManager in window: ${('PushManager' in window)})` })
                return
            }
            const permissionResult = await new Promise((resolve, reject) => {
                const fnForOldBrowsers = result => resolve(result)
                const permissionResult = Notification.requestPermission(fnForOldBrowsers)
                if (permissionResult) {
                    // promise for recent browsers
                    permissionResult.then(resolve).catch(reject)
                }
            })

            if (permissionResult !== 'granted') {
                // eslint-disable-next-line
                console.warn(`Notification permission status is [${permissionResult}]. Skip`)
                this.$store.commit('dbg/log', { message: `notif persmission status: ${permissionResult}` })
                return Vue.prototype.$api.post('/devlog', { permissionResult })
            }
            return this.notificationRegisterSubscription(filters)
        },

        async notificationUnsubscribe(filters) {
            if (!this.notificationEnabled) {
                return
            }
            try {
                const registration = await navigator.serviceWorker.getRegistration()
                const subscription = await registration.pushManager.getSubscription()

                if (!subscription) {
                    return
                }

                const token = this.$store.getters['notif/token']

                await this.$store.dispatch('notif/unsubscribe', { token, subscription, slug: this.notificationSalepointSlug, filters })
                return subscription.unsubscribe()
            } catch (error) {
                // eslint-disable-next-line
                console.error(error)
                this.$store.commit('dbg/log', { message: `notif unsubscribe: ${error.message}` })
                return Vue.prototype.$api.post('/devlog', { type: 'notification-unregister', error: error.message }).catch(console.error)
            }
        }
    }
}
