<template>
    <v-menu v-model="show">
        <template #activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                :loading="loading"
                small
                rounded
                color="primary">
                {{ $t('shared-front:order.calendarlinksbtn') }}
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item :href="icsUrl" target="_blank">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('shared-front:order.calendarlinksics') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item v-for="link in links" :key="link.url" :href="link.url" target="_blank">
                <v-list-item-content>
                    <v-list-item-title>{{ $t(`shared-front:order.calendarlinks.${link.type}`) }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'acs-order-ics-btn',
    props: {
        slug: { type: String },
        orderId: { type: String }
    },

    data: () => ({
        linksObject: [],
        show: false,
        loading: false
    }),

    computed: {
        icsUrl() {
            const base = this.$api.defaults.baseURL
            return `${base}/salepoints/${this.slug}/ecom/orders/${this.orderId}/timeframes/events.ics`
        },

        links() {
            return Object.entries(this.linksObject).map(([type, url]) => ({
                type,
                url
            }))
        }
    },

    watch: {
        show(v) {
            v && !this.links.length && this.load()
        }
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('order/getICSLinks', { slug: this.slug, id: this.orderId })
                .then(links => (this.linksObject = links))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
