<template>
    <v-card flat class="mt-4">
        <v-alert type="error" v-model="error">
            {{ $t('product.qrError') }}
        </v-alert>
        <v-img
            :src="ticketQrUrl"
            :alt="$t('QR code')"
            :title="$t('QR code')"
            @loadstart="start"
            @load="end(false)"
            @error="end(true)">
            <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <acs-base-loader v-model="loading" />
                </v-row>
            </template>
        </v-img>
    </v-card>
</template>

<script>
export default {
    name: 'acs-order-ticket-qr',

    props: {
        orderId: { type: String },
        slug: { type: String }
    },

    data: () => ({
        loading: false,
        error: null
    }),

    computed: {
        ticketQrUrl() {
            return `${this.$api.defaults.baseURL}/salepoints/${this.slug}/ecom/orders/${this.orderId}/qr`
        }
    },

    methods: {

        start() {
            this.error = false
            this.loading = true
        },

        end(error) {
            this.error = error
            this.loading = false
        }
    }
}
</script>
