<template>
    <acs-base-number-field
        v-model="num"
        :field-props="attributes"
        :allow-decimal="true"
        :rules="required ? [$required] : []"
    />
</template>

<script>
import AcsBaseNumberField from '@/shared/components/base/AcsBaseNumberField'

export default {
    name: 'acs-product-option-type-float',
    components: { AcsBaseNumberField },
    props: {
        value: { type: String },
        item: { type: Object },
        label: { type: String },
        required: { type: Boolean }
    },
    computed: {
        attributes() {
            return this.item.attrs.reduce((acc, attr) => {
                acc[attr.name] = attr.value
                return acc
            }, {
                label: this.label + (this.required ? ' *' : ''),
                hideDetails: true,
                outlined: true,
                dense: true
            })
        },

        num: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
