<template>
    <acs-base-container center>
        <v-card flat>
            <v-card-title class="flex-nowrap">
                <v-img max-width="80" src="/img/pay-success.svg" />
                <div class="text-left pl-4">
                    <div class="text-h6">{{ $t('cartdone.success') }}</div>
                    <div class="text-subtitle-1">{{ $t('cartdone.successHelp') }}</div>
                </div>
            </v-card-title>

            <v-simple-table class="acs-cart-success--table text-left mb-3 px-0" v-if="order">
                <template #default>
                    <thead>
                        <tr>
                            <td colspan="2" class="text-h6">{{ order.salepoint_details.name }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-h6">{{ order.service_details.name }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="order.production_deadline">
                            <td class="text-body-1">{{ $t('cartdone.productiondeadline') }}</td>
                            <td class="text-body-1"><strong>{{ $t('cartdone.productiondeadlineformat', { date: order.production_deadline }) }}</strong></td>
                        </tr>
                        <tr class="acs-order--number">
                            <td class="text-body-1">{{ $t('cartdone.number') }}</td>
                            <td><strong>{{ order.code }}</strong></td>
                        </tr>
                        <tr :class="statusClass">
                            <td class="text-body-1">{{ $t('cartdone.status') }}</td>
                            <td class="text-body-1"><strong>{{ $t(`shared-front:order.status.${order.status}`) }}</strong></td>
                        </tr>
                        <tr v-if="order.timeframes && order.timeframes.length">
                            <td>{{ $t('cartdone.timeframe', { count: order.timeframes.length }) }}</td>
                            <td>
                                <p v-for="date in order.timeframes" :key="date" class="my-0">{{ timeframeFormatter.format(new Date(date)) }}</p>
                            </td>
                        </tr>
                        <tr :class="alertClass">
                            <td class="pa-0" colspan="2">
                                <acs-cart-status-alert
                                    v-if="order"
                                    :order-id="order.id"
                                    :disabled="order.status === 'delivered'"
                                    ref="statusAlert"
                                    class="py-5 px-4" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('cartdone.date') }}</td>
                            <td>{{ $t('cartdone.dateformat', { date: order.created_at }) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('cartdone.ordermode') }}</td>
                            <td>{{ $t(`shared-front:order.modes.${order.mode}`) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('cartdone.ticket') }}</td>
                            <td>
                                <a :href="order.links_ticket" target="_blank">{{ $t('cartdone.ticketdownload') }}</a>
                                <acs-order-ticket-qr :slug="slug" :order-id="order.id" />
                            </td>
                        </tr>
                        <tr v-if="order.timeframes && order.timeframes.length">
                            <td>{{ $t('cartdone.calendar') }}</td>
                            <td>
                                <acs-order-ics-btn :slug="slug" :order-id="order.id" />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot v-if="!connected">
                        <tr>
                            <td colspan="2" class="pt-5 px-1">
                                <acs-pay-create-account />
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>
            <v-card-text v-else-if="loading">
                <p>{{ $t('cartdone.orderwait') }}</p>
                <acs-base-loader permanent />
            </v-card-text>
            <v-alert v-model="cartPaymentValidationTimeout" type="warning">
                <div class="text-start" v-marked.inline="$t('cartdone.paymentValidationError')"></div>
            </v-alert>

            <v-card-actions class="flex-column">
                <v-btn
                    rounded
                    color="primary"
                    class="mx-0 mt-5"
                    block
                    :disabled="loading"
                    :to="{ name: getContextRouteName('salepoint') }">
                    {{ $t('cartdone.back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsCartStatusAlert from '@/components/cart/AcsCartStatusAlert'
import AcsPayCreateAccount from '@/components/pay/AcsPayCreateAccount'
import AcsOrderIcsBtn from '@/components/order/AcsOrderIcsBtn'
import AcsOrderTicketQr from '@/components/order/AcsOrderTicketQr'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'cart-success',
    components: { AcsBaseContainer, AcsCartStatusAlert, AcsPayCreateAccount, AcsOrderIcsBtn, AcsOrderTicketQr },
    props: {
        slug: { type: String },
        aslot: { type: String },
        orderId: { type: String }
    },
    mixins: [RouteMixin],
    data: () => ({
        cartPaymentValidationTimeout: false,
        interval: null,
        loading: false,
        retry: 0,
        maxRetry: 1,
        order: null
    }),
    computed: {
        cart() {
            return this.$store.getters['cart/cart']
        },
        statusClass() {
            const status = this.order?.status

            if (status === 'accepted') {
                return ['success--text']
            }
            if (status === 'prepared') {
                return ['white--text', 'success']
            }
            if (status === 'delivered') {
                return ['grey', 'lighten-4']
            }
            return null
        },
        alertClass() {
            const status = this.order?.status

            if (status === 'delivered') {
                return ['grey', 'lighten-4']
            }
            return null
        },
        lang() {
            return this.$store.getters['ln/current']
        },
        connected() {
            return this.$store.getters['auth/connected']
        },

        timeframeFormatter() {
            return new Intl.DateTimeFormat(this.$store.getters['ln/currentWithRegion'], {
                dateStyle: 'medium',
                timeStyle: this.order.service_details.timeframe_day ? undefined : 'short'
            })
        },

        isOnlyTerminal() {
            const salepoint = this.$store.getters['sp/current']
            return this.$store.getters['auth/isOnly'](salepoint.id, 'terminal')
        }
    },
    watch: {
        orderId: 'fromOrder'
    },
    mounted() {
        this.loading = true
        this.cartPaymentValidationTimeout = false
        this.retry = 0
        return Promise
            .all([
                this.$store.dispatch('sp/current', { slug: this.slug }),
                !this.orderId ? this.$store.dispatch('cart/lock', { slug: this.slug }) : null,
                this.orderId ? this.fromOrder() : this.fromCart()
            ])
            .catch(err => {
                this.loading = false
                this.$err(err)
            })
    },
    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    sockets: {
        error(err) {
            this.$err(err)
        },

        productiondeadline(data) {
            if (data.id !== this.orderId) {
                return
            }
            this.order.production_deadline = data.production_deadline
        },

        orderstatusupdated(data) {
            if (data.id !== this.orderId) {
                return
            }
            this.order.status = data.status
        }
    },
    methods: {
        fromOrder() {
            return this.$store.dispatch('order/getPublicData', { slug: this.slug, id: this.orderId })
                .then(order => {
                    if (this.isOnlyTerminal) {
                        return this.$router.replace({
                            name: this.getContextRouteName('menulist'),
                            query: { payment: 'done' }
                        })
                    }

                    this.order = order
                    this.loading = false
                    return this.$store.dispatch('order/addInCache', {
                        slug: this.slug,
                        orderId: order.id,
                        order: order
                    })
                })
        },

        fromCart() {
            return this.getOrder()
        },

        getOrder() {
            const cartId = this.$store.getters['cart/currentCartId']

            this.loading = true
            return this.$store.dispatch('order/getFromCart', { slug: this.slug, cartId })
                .then(order => {
                    // reset des filtres, la commande est finalisée
                    this.$store.dispatch('prod/saveFilters', {})

                    this.order = order

                    // intégration dans le cache local
                    this.$store.dispatch('order/addInCache', { slug: this.slug, orderId: order.id, order })
                    // suppression du panier
                    return this.$store.dispatch('cart/remove', { slug: this.slug })
                })
                .then(() => {
                    // redirect sur la page pérenne de commande
                    this.$store.dispatch('socket/start', { salepoint_id: this.order.id })
                    this.loading = false
                    if (this.orderId === this.order.id) {
                        // pour éviter la navigation redondante
                        return
                    }
                    return this.$router.push({
                        name: this.getContextRouteName('cartsuccessorder'),
                        params: {
                            orderId: this.order.id
                        }
                    })
                })
                .catch(err => {
                    if (err.response?.status !== 404 || this.retry >= this.maxRetry) {
                        // on ne lance l'erreur qu'en cas d'erreur serveur autre
                        // que le notfound
                        this.cartPaymentValidationTimeout = true
                        return
                    }
                    this.retry += 1
                    return this.getOrder()
                })
                .finally(() => (this.loading = false))
        },

        confirmStay() {
            if (this.orderId || !this.loading) {
                return false
            }
            window.alert(this.$i18n.t('cart.stay'))
            return true
        },

        beforeWindowUnload(e) {
            if (this.confirmStay()) {
                e.preventDefault()
                // Chrome requires returnValue to be set
                e.returnValue = ''
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        const stay = this.confirmStay()
        next(!stay)
    }
}
</script>

<style lang="sass" scoped>
.acs-order--number
    strong
        font-size: 2em

.acs-cart-success--table
    tbody td:first-child
        width: 46%

</style>
