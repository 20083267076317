<template>
    <v-card-text class="acs-product--information pb-0">
        <div class="px-0 text-subtitle-2">{{ $t('product.tags') }}</div>
        <span class="text-caption">{{ tags.join(', ') }}</span>
    </v-card-text>
</template>

<script>
export default {
    name: 'acs-product-tags',
    props: {
        tags: { type: Array }
    }
}
</script>
