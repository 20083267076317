<template>
    <v-select
        v-model="selected"
        :label="label + (required ? ' *' : '')"
        :items="item.list"
        v-bind="attributes"
        item-text="value"
        item-value="value"
        :multiple="multiple"
        dense
        outlined
        hide-details
        clearable
        :rules="required ? [$required] : []">
        <template #item="{ item }">
            <span>{{ item | quickTranslate('value', translate) }}</span>
            <v-spacer />
            <span
                v-if="showPrices"
                class="caption option-price"
                v-html="$options.filters.sign(item.price, currency, { small: true, full: true })"></span>
        </template>
        <template #selection="{ item }">
            {{ item | quickTranslate('value', translate) }}
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'acs-product-option-type-list',
    props: {
        value: { type: String },
        item: { type: Object },
        label: { type: String },
        required: { type: Boolean },
        attributes: { type: Object, default: () => ({}) },
        translate: { type: Boolean }
    },
    computed: {
        currency() {
            return this.$store.getters['sp/current'].currency_users
        },

        multiple() {
            const multiple = (this.attributes.multiple || '').toLowerCase()
            return ['1', 'on', 'yes', 'true', 'multiple'].includes(multiple)
        },

        showPrices() {
            return !!this.item.list?.find(l => l.price)
        },

        selected: {
            get() {
                if (this.multiple && !Array.isArray(this.value)) {
                    return this.value.split(',')
                }
                return this.value
            },
            set(v) {
                if (Array.isArray(v)) {
                    v = v.join(',')
                }
                this.$emit('input', v)
            }
        }
    }
}
</script>
