var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-space-between align-content-center"},[_c('span',{staticClass:"align-self-center primary--text grow"},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","depressed":"","x-small":"","color":"primary"}},[_c('v-icon',[_vm._v("$vuetify.icons.discount")])],1),_c('strong',[_vm._v(_vm._s(_vm.$t('cartpay.discount')))])],1),_c('acs-base-number-field',{ref:"field",attrs:{"rules":_vm.rules,"field-props":{
            outlined: true,
            singleLine: true,
            hideDetails: !_vm.hasError,
            class: 'shrink',
            suffix: _vm.currency.toUpperCase(),
            style: 'max-width: 120px'
        }},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }