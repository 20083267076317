<template>
    <div class="d-flex justify-space-between align-content-center">
        <span class="align-self-center primary--text grow">
            <v-btn
                fab
                depressed
                x-small
                color="primary"
                class="mr-2">
                <v-icon>$vuetify.icons.tip</v-icon>
            </v-btn>
            <strong>{{ $t('cartpay.tip') }}</strong>
        </span>
        <acs-base-number-field
            ref="field"
            v-model="tip"
            :rules="rules"
            :field-props="{
                outlined: true,
                singleLine: true,
                hideDetails: !hasError,
                class: 'shrink',
                suffix: currency.toUpperCase(),
                style: 'max-width: 120px'
            }" />
    </div>
</template>

<script>
import AcsBaseNumberField from '@/shared/components/base/AcsBaseNumberField'
import { debounce } from 'lodash'

export default {
    name: 'acs-cart-tip',
    components: { AcsBaseNumberField },
    props: {
        value: { type: [String, Number] },
        debounce: { type: Boolean }
    },
    data: () => ({
        waiting: false,
        hasError: false,
        min: 0,
        max: 1000
    }),
    computed: {
        tip: {
            get() {
                return this.value
            },
            set(v) {
                if (!this.debounce) {
                    return this.$emit('input', Number(v))
                }
                this.waiting = true
                this.$emit('waiting', true)
                this.setTip(v)
            }
        },
        currency() {
            return this.$store.getters['sp/current'].currency_users || ''
        },
        rules() {
            const rules = [this.$ranged(this.min, this.max, true)]
            if (this.waiting) {
                rules.push(this.notWaiting)
            }
            return rules
        }
    },
    mounted() {
        window.setTimeout(() => this.$refs.field.focus(), 200)
    },
    methods: {
        setTip: debounce(function(v) {
            this.waiting = false
            // si le champ est invalide, on n'emit pas la valeur
            v = Number(v)
            if (v >= this.min && v <= this.max) {
                this.hasError = false
                this.$emit('input', v)
            } else {
                this.hasError = true
            }
        }, 700),

        notWaiting() {
            return !this.waiting
        }
    }
}
</script>
