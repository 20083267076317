<template>
    <v-alert
        :value="messages.length > 0"
        class="mx-3 mt-4 pb-2"
        color="primary"
        text>
        <div v-for="(msg, i) in messages" :key="i" v-marked="msg.message"></div>

        <v-input type="hidden" hide-details :rules="[$required]" :value="isOk" />
    </v-alert>
</template>

<script>
export default {
    name: 'acs-product-alert-top',

    computed: {
        messages() {
            return this.$store.getters['prod/alertMessages']
        },

        isOk() {
            return !this.messages.find(msg => !msg.optional)
        }
    }
}
</script>
